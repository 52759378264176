import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import "../../styles/bootstrap.css";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
// import ReviewItem from "../../components/ReviewItem";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function ContentAnalytics() {
  const axios = useAxios();

  const [isActive, setActive] = useState(false);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const [merchantData, setMerchantData] = useState({});
  const [merchantData2, setMerchantData2] = useState({});
  const [loading, setLoading] = useState(false);

  const [contentAnalysis, setContentAnalysis] = useState([]);
  const [keywordsMerchants, setKeywordMerchants] = useState([]);

  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  function handleClick() {
    setActive(!isActive);
  }

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get("/");

        const responseMerchant = await axios.get(
          `/merchant-profile/${response.data.website || "www.amazon.fr"}`
        );

        const responseData = await fetch(
          "https://api.veritatrust.com/v1/Keywords/" +
            responseMerchant.data[0].id
        );

        const contanAnalysis = await responseData.json();
        const a = contanAnalysis.sort(
          (a, b) => new Date(b.last_updated) - new Date(a.last_updated)
        );
        setKeywordMerchants(a.slice(0, 15));
        setMerchantData(responseMerchant.data[0]);
        setMerchantData2(responseMerchant.data[0]);
      } catch (error) {}
    };

    fetchAuthStatus();
  }, []);

  async function analysisContent() {
    try {
      setLoading(true); // Démarre le chargement
      const fetchAuthStatus = async () => {
        try {
          // Première requête
          const responseData = await fetch(
            "https://api.veritatrust.com/v1/content-analysis-ai/" +
              merchantData.id
          );
          const contanAnalysis = await responseData.json();
          setContentAnalysis(contanAnalysis);
          console.log("Contenu de l'analyse récupéré :", contanAnalysis);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération de content analysis :",
            error
          );
        }

        // Délai de 5 secondes pour la deuxième requête
        setTimeout(async () => {
          try {
            const responseMerchant = await axios.get(
              `/merchant-profile/${merchantData.website || "www.amazon.fr"}`
            );
            setMerchantData2(responseMerchant.data[0]);
            console.log(
              "Données du marchand récupérées :",
              responseMerchant.data[0]
            );

            const responseData = await fetch(
              "https://api.veritatrust.com/v1/Keywords/" +
                responseMerchant.data[0].id
            );

            const contanAnalysis = await responseData.json();
            const a = contanAnalysis.sort(
              (a, b) => new Date(b.last_updated) - new Date(a.last_updated)
            );
            setKeywordMerchants(a.slice(0, 15));
          } catch (error) {
            console.error(
              "Erreur lors de la récupération des données du marchand :",
              error
            );
          } finally {
            setLoading(false); // Fin du chargement après la deuxième requête
          }
        }, 5000); // 5000 millisecondes = 5 secondes
      };

      await fetchAuthStatus();
    } catch (error) {
      console.error("Erreur dans analysisContent :", error);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="container-fluid">
        <SideBar isActive={isActive} handleClick={handleClick}></SideBar>

        <main>
          <NavBar
            proposTitle={"Content analytics"}
            handleClick={handleClick}
          ></NavBar>
          <div class="container tag-cloud mx-auto">
            <div class="row">
              <div class="col-md-8 mx-auto mb-4">
                <div class="input-group mb-3">
                  <select
                    class="form-select form-select-lg"
                    aria-label="Default select example"
                  >
                    <option selected>Select period</option>
                    <option value="1">Last week</option>
                    <option value="2">Last month</option>
                    <option value="3">Last six months</option>
                    <option value="4">Last year</option>
                  </select>
                  <button
                    class="btn btn-primary"
                    type="button"
                    disabled={loading}
                    onClick={analysisContent}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                      </>
                    ) : (
                      "Analyse content"
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mx-auto">
                <ul
                  className="cloud"
                  role="navigation"
                  aria-label="Webdev word cloud"
                >
                  {keywordsMerchants &&
                    keywordsMerchants.map((keywordObj, index) => (
                      <li key={index}>
                        <span data-weight={keywordObj.occurrence_count}>
                          {keywordObj.keyword}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <div class="container info-ia">
            <div class="row">
              <p class="text-end">
                <small>
                  This repport is generated on{" "}
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                      <span role="status">Loading...</span>
                    </>
                  ) : (
                    <>{moment(merchantData2.last_analysis_date).calendar()}</>
                  )}
                </small>
              </p>
            </div>
            <div class="row">
              <div class="col text-center">
                <h2>Highlights and Customer Success Stories</h2>
                <div class="card danger">
                  <i class="flaticon-information"></i>
                  <p>
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                      </>
                    ) : (
                      <>{merchantData2.last_negative_summary}</>
                    )}
                  </p>{" "}
                </div>
              </div>
              <div class="col text-center">
                <h2>Challenges and Areas for Improvement</h2>
                <div class="card success">
                  <i class="flaticon-check"></i>
                  <p>
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                      </>
                    ) : (
                      <>{merchantData2.last_positive_summary}</>
                    )}
                  </p>{" "}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default ContentAnalytics;
