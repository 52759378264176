import React from "react";
import ReactDOM from "react-dom/client";
//import ReactDOM from "react-dom";
import i18n from "i18next";
import { useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import WidgetResumeComponentBlockVeritaTrust from "./components/WidgetResumeComponentBlockVeritaTrust";
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationIT from "./locales/it/translation.json";
import ChekoutCompanyDetails from "./pages/CheckoutCompanyDetails";
import ChekoutConfirmData from "./pages/CheckoutConfirmData";
import CheckoutPaymentConfirmation from "./pages/CheckoutPaymentConfirmation";
import CheckoutPaymentValidation from "./pages/CheckoutPaymentValidation";
import ChekoutSelectSubscription from "./pages/CheckoutSelectSubscription";
import CollectPage from "./pages/CollectPage";
import CompleteProfilePage from "./pages/CompleteProfilePage";
import ContentAnalytics from "./pages/ContentAnalytics";
import DashboardPage from "./pages/DashboardPage";
import ManagePage from "./pages/ManagePage";
import SettingsPage from "./pages/SettingsPage";
import ShowcasePage from "./pages/ShowcasePage";
import UpgradePage from "./pages/UpgradePage";
import WidgetsPage from "./pages/WidgetsPage";
import LoginPage from "./pages/loginPage";
import LogupPage from "./pages/logupPage";
import ShowcasePublic from "./pages/showcasePublic";
import store from "./store";
import "./styles/index.css";
import { ProvideAxios, useAxios } from "./utils/hook/useAxios";

// import Header from "./components/Header";
// import Footer from "./components/Footer";

// Fonction pour détecter la langue du navigateur
const getBrowserLanguage = () => {
  return navigator.language || navigator.userLanguage;
};

i18n

  .use(initReactI18next) // initialise i18next pour une utilisation avec React
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      fr: {
        translation: translationFR,
      },
      it: {
        translation: translationIT,
      },
    },
    fallbackLng: "en", // langue par défaut
    debug: false, // afficher les messages de débogage
    interpolation: {
      escapeValue: false, // permet d'inclure des éléments HTML dans les traductions
    },
  });

const PrivateRoute = ({ Component }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({}); // Ajouter un état de chargement
  const axios = useAxios();

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const res = await axios.get("/auth/check-auth");
        setIsAuthenticated(res.data.isAuthenticated);
        console.log(res.data);
        setIsLoading(false); // Mettre à jour l'état de chargement une fois l'authentification terminée

        const response = await axios.get("/");
        setUser(response.data);

        console.log(response);
      } catch (error) {
        console.error(error);
        setIsLoading(false); // Mettre à jour l'état de chargement en cas d'erreur
      }
    };

    fetchAuthStatus();
  }, []);

  // Afficher le composant de chargement si l'authentification est en cours
  if (isLoading) {
    return <div>Chargement en cours...</div>;
  }

  return isAuthenticated ? (
    user.website ? (
      user.status === "connected" ? (
        <Component />
      ) : (
        <CompleteProfilePage />
      )
    ) : (
      <CompleteProfilePage />
    )
  ) : (
    <LoginPage />
  );
};

const defaultLang = window.localStorage.getItem("langPage") || "en";

const RedirectWithQuery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.toString();
  const targetPath = `/${defaultLang}${location.pathname}${
    query ? `?${query}` : ""
  }`;

  return <Navigate to={targetPath} replace />;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ProvideAxios>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/:lang/signin" element={<LoginPage />} />
            <Route
              path="/signin"
              element={<Navigate to={`/${defaultLang}/signin`} />}
            />
            <Route
              path="/:lang/content-analytics"
              element={<ContentAnalytics />}
            />
            <Route
              path="/content-analytics"
              element={<Navigate to={`/${defaultLang}/content-analytics`} />}
            />
            <Route path="/:lang/signup" element={<LogupPage />} />
            <Route
              path="/signup"
              element={<Navigate to={`/${defaultLang}/signup`} />}
            />
            <Route path="/" element={<Navigate to={`/${defaultLang}/`} />} />
            <Route
              path="/:lang"
              element={<PrivateRoute Component={DashboardPage} />}
            />
            <Route
              path="/manage"
              element={<Navigate to={`/${defaultLang}/manage`} />}
            />
            <Route
              path="/:lang/manage"
              element={<PrivateRoute Component={ManagePage} />}
            />
            <Route
              path="/collect"
              element={<Navigate to={`/${defaultLang}/collect`} />}
            />
            <Route
              path="/:lang/collect"
              element={<PrivateRoute Component={CollectPage} />}
            />
            <Route
              path="/showcase"
              element={<Navigate to={`/${defaultLang}/showcase`} />}
            />
            <Route
              path="/:lang/showcase"
              element={<PrivateRoute Component={ShowcasePage} />}
            />
            <Route
              path="/settings"
              element={<Navigate to={`/${defaultLang}/settings`} />}
            />
            <Route
              path="/:lang/settings"
              element={<PrivateRoute Component={SettingsPage} />}
            />
            <Route path="/upgrade" element={<RedirectWithQuery />} />
            <Route
              path="/:lang/upgrade"
              element={<PrivateRoute Component={UpgradePage} />}
            />
            <Route
              path="/checkout/select-subscription"
              element={<RedirectWithQuery />}
            />
            <Route
              path="/:lang/checkout/select-subscription"
              element={<PrivateRoute Component={ChekoutSelectSubscription} />}
            />
            <Route
              path="/checkout/company-details"
              element={<RedirectWithQuery />}
            />
            <Route
              path="/:lang/checkout/company-details"
              element={<PrivateRoute Component={ChekoutCompanyDetails} />}
            />
            <Route
              path="/checkout/confirmation-data"
              element={<RedirectWithQuery />}
            />
            <Route
              path="/:lang/checkout/confirmation-data"
              element={<PrivateRoute Component={ChekoutConfirmData} />}
            />
            <Route
              path="/checkout/payment-validation"
              element={<RedirectWithQuery />}
            />
            <Route
              path="/:lang/checkout/payment-validation"
              element={<PrivateRoute Component={CheckoutPaymentValidation} />}
            />
            <Route
              path="/checkout/payment-confirmation"
              element={<RedirectWithQuery />}
            />
            <Route
              path="/:lang/checkout/payment-confirmation"
              element={<PrivateRoute Component={CheckoutPaymentConfirmation} />}
            />
            <Route path="/upgrade-public" element={<RedirectWithQuery />} />
            <Route path="/:lang/upgrade-public" element={<UpgradePage />} />
            <Route
              path="/checkout/select-subscription-public"
              element={<RedirectWithQuery />}
            />
            <Route
              path="/:lang/checkout/select-subscription-public"
              element={<ChekoutSelectSubscription />}
            />
            <Route
              path="/checkout/company-details-public"
              element={<RedirectWithQuery />}
            />
            <Route
              path="/:lang/checkout/company-details-public"
              element={<ChekoutCompanyDetails />}
            />
            <Route
              path="/checkout/confirmation-data-public"
              element={
                <Navigate
                  to={`/${defaultLang}/checkout/confirmation-data-public`}
                />
              }
            />
            <Route
              path="/:lang/checkout/confirmation-data-public"
              element={<ChekoutConfirmData />}
            />
            <Route
              path="/checkout/payment-validation-public"
              element={
                <Navigate
                  to={`/${defaultLang}/checkout/payment-validation-public`}
                />
              }
            />
            <Route
              path="/:lang/checkout/payment-validation-public"
              element={<CheckoutPaymentValidation />}
            />
            <Route
              path="/checkout/payment-confirmation-public"
              element={
                <Navigate
                  to={`/${defaultLang}/checkout/payment-confirmation-public`}
                />
              }
            />
            <Route
              path="/:lang/checkout/payment-confirmation-public"
              element={<CheckoutPaymentConfirmation />}
            />
            <Route
              path="/showcase-public"
              element={<Navigate to={`/${defaultLang}/showcase-public`} />}
            />
            <Route path="/:lang/showcase-public" element={<ShowcasePublic />} />
            <Route
              path="/settings-public"
              element={<Navigate to={`/${defaultLang}/settings-public`} />}
            />
            <Route path="/:lang/settings-public" element={<SettingsPage />} />
            <Route
              path="/collect-public"
              element={<Navigate to={`/${defaultLang}/collect-public`} />}
            />
            <Route path="/:lang/collect-public" element={<CollectPage />} />
            <Route
              path="/completepage"
              element={<Navigate to={`/${defaultLang}/completepage`} />}
            />
            <Route
              path="/:lang/completepage"
              element={<CompleteProfilePage />}
            />
            <Route
              path="/manage-public"
              element={<Navigate to={`/${defaultLang}/manage-public`} />}
            />
            <Route path="/:lang/manage-public" element={<ManagePage />} />
            <Route
              path="/widget/:website"
              element={<Navigate to={`/${defaultLang}/widget/:website`} />}
            />
            <Route
              path="/:lang/widget/:website"
              element={<WidgetResumeComponentBlockVeritaTrust />}
            />
            <Route
              path="/trustboxes/:templateId"
              element={
                <Navigate to={`/${defaultLang}/trustboxes/:templateId`} />
              }
            />
            <Route
              path="/:lang/trustboxes/:templateId"
              element={<WidgetsPage />}
            />
          </Routes>
        </Router>
      </Provider>
    </ProvideAxios>
  </React.StrictMode>
);
